<template>
  <div class="campaign-initiated-calls">
    <div class="d-flex justify-content-end mb-2 table-actions">
      <b-button
        class="px-3 py-2"
        variant="primary"
        @click="downloadInitiatedCalls"
        :disabled="liveCalls.length <= 0"
      >
        {{ $t('auto-dialer.initiated-calls.table.actions.download') }}
      </b-button>
    </div>

    <data-table
      ref="initiatedCallsTable"
      :data="liveCalls"
      :fields="translatedTableFields"
      :pagination="pagination"
    >
    </data-table>
  </div>
</template>

<script>
import DataTable from '../../../elements/DataTable.vue'
import AutoDialerService from '../../../services/autoDialer.service'
import { downloadCSV } from '../../../utils/csv'
import { mapGetters } from 'vuex'

export default {
  components: {
    DataTable
  },
  props: {
    initiatedCalls: {
      type: Array,
      default: () => []
    },
    campaignId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      liveCalls: [],
      pagination: {
        perPage: 10
      },
      interval: null
    }
  },
  computed: {
    ...mapGetters(['currentTenant']),
    translatedTableFields() {
      return [
        {
          name: 'name',
          title: this.$t('auto-dialer.initiated-calls.table.columns.name')
        },
        {
          name: 'phone',
          title: this.$t('auto-dialer.initiated-calls.table.columns.phone')
        },
        {
          name: 'currentTrial',
          title: this.$t(
            'auto-dialer.initiated-calls.table.columns.currentTrial'
          )
        }
      ]
    }
  },
  methods: {
    downloadInitiatedCalls() {
      const data = this.liveCalls.map(call => ({
        name: call.name,
        phone: call.phone,
        currentTrial: call.currentTrial
      }))
      downloadCSV(
        data,
        `initiated_calls_${this.campaignId}_${new Date().toISOString()}.csv`
      )
    },
    async getInitiatedCalls() {
      try {
        const res = await AutoDialerService.getInitiatedCalls(this.campaignId)
        this.liveCalls = res.data.calls
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      }
    }
  },
  mounted() {
    this.liveCalls = this.initiatedCalls

    this.interval = setInterval(async () => {
      await this.getInitiatedCalls()
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  watch: {
    async currentTenant() {
      await this.getInitiatedCalls()
    }
  }
}
</script>
